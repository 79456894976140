// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseURL: 'https://foodcloud.in/foodies/index.php/',
  mediaURL: 'https://foodcloud.in/foodies/uploads/',
  onesignal: {
    appId: 'YOUR_APP_ID',
    googleProjectNumber: 'GOOGLE_PROJECT_NUMBER',
    restKey: 'REST_KEY'
  },
  general: {
    symbol: '₹',
    code: 'INR'
  },
  authToken: '123456'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

/*
  Authors : initappz (Rahul Jograna)
  Website : https://initappz.com/
  App Name : ionic 5 foodies app
  Created : 28-Feb-2021
  This App Template Source code is licensed as per the
  terms found in the Website https://initappz.com/license
  Copyright and Good Faith Purchasers © 2020-present initappz.
*/
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './layouts/admin/admin.component';
import { AuthComponent } from './layouts/auth/auth.component';
import { AuthGuard } from './guard/auth.guard';
import { LeaveGuard } from './leaved/leaved.guard';

const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'paytmcallback',
        loadChildren: () => import('./pages/paytmcallback/paytmcallback.module').then(m => m.PaytmcallbackModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'custom-order-add',
        loadChildren: () => import('./pages/custom-order-add/custom-order-add.module').then(m => m.CustomOrderAddModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'custom-order',
        loadChildren: () => import('./pages/custom-order/custom-order.module').then(m => m.CustomOrderModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'custom-orders',
        loadChildren: () => import('./pages/custom-orders/custom-orders.module').then(m => m.CustomOrdersModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'api-custom-order-status',
        loadChildren: () => import('./pages/api-custom-order-status/api-custom-order-status.module').then(m => m.ApiCustomOrderStatusModule)
      },
      {
        path: 'api-order-status',
        loadChildren: () => import('./pages/api-order-status/api-order-status.module').then(m => m.ApiOrderStatusModule)
      },
      {
        path: 'payments',
        loadChildren: () => import('./pages/payments/payments.module').then(m => m.PaymentsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'custom-order-stats',
        loadChildren: () => import('./pages/stats-custom-order/stats-custom-order.module').then(m => m.StatsCustomOrderModule)
      },

      {
        path: 'stats',
        loadChildren: () => import('./pages/stats/stats.module').then(m => m.StatsModule)
      },

      {
        path: 'invoice',
        loadChildren: () => import('./pages/invoice/invoice.module').then(m => m.InvoiceModule)
      },

      {
        path: 'manage-orders',
        loadChildren: () => import('./pages/manage-orders/manage-orders.module').then(m => m.ManageOrdersModule)
      },
      {
        path: 'manage-custom-orders',
        loadChildren: () => import('./pages/manage-custom-orders/manage-custom-orders.module').then(m => m.ManageCustomOrdersModule)
      },
      {
        path: 'user-registration',
        loadChildren: () => import('./pages/user-registration/user-registration.module').then(m => m.UserRegistrationModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'contacts',
        loadChildren: () => import('./pages/contacts/contacts.module').then(m => m.ContactsModule),
        canDeactivate: [LeaveGuard]
      },
      {
        path: 'products',
        loadChildren: () => import('./pages/products/products.module').then(m => m.ProductsModule)
      },
      {
        path: 'manage-products',
        loadChildren: () => import('./pages/manage-products/manage-products.module').then(m => m.ManageProductsModule)
      },
      {
        path: 'reviews',
        loadChildren: () => import('./pages/reviews/reviews.module').then(m => m.ReviewsModule)
      },
      {
        path: 'profile',
        loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfileModule)
      },
      {
        path: 'categories',
        loadChildren: () => import('./pages/categories/categories.module').then(m => m.CategoriesModule)
      }
    ]
  },
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: 'login',
        loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule),
      },
      {
        path: 'reset',
        loadChildren: () => import('./pages/reset/reset.module').then(m => m.ResetModule)
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'dashboard'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

/*
  Authors : initappz (Rahul Jograna)
  Website : https://initappz.com/
  App Name : ionic 5 foodies app
  Created : 28-Feb-2021
  This App Template Source code is licensed as per the
  terms found in the Website https://initappz.com/license
  Copyright and Good Faith Purchasers © 2020-present initappz.
*/
import { Injectable } from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  state: string;
  target?: boolean;
  name: string;
  type?: string;
  children?: ChildrenItems[];
}

export interface MainMenuItems {
  state: string;
  main_state?: string;
  target?: boolean;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  children?: ChildrenItems[];
}

export interface Menu {
  label: string;
  main: MainMenuItems[];
}

const MENUITEMS = [
  {
    label: 'Main',
    main: [
      {
        state: 'dashboard',
        name: 'View Online Orders',
        type: 'link',
        icon: 'ti-shopping-cart'
      },
      {
        state: 'custom-order-add',
        name: 'Place Your Order',
        type: 'link',
        icon: 'ti-package'
      },
      /*{
        state: 'custom-order',
        name: 'Custom Order',
        type: 'link',
        icon: 'ti-home'
      },*/
      {
        state: 'custom-orders',
        name: 'View Custom Orders',
        type: 'link',
        icon: 'ti-shopping-cart-full'
      }
    ],
  },
  {
    label: 'Manage Your menu',
    main: [
      {
        state: 'categories',
        name: 'Categories',
        type: 'link',
        icon: 'ti-folder'
      },
      {
        state: 'products',
        name: 'Products',
        type: 'link',
        icon: 'ti-envelope'
      },
    ]
  },
  {
    label: 'Your Finance',
    main: [
      {
        state: 'stats',
        name: 'Online Order Stats',
        type: 'link',
        icon: 'ti-stats-up'
      },
      {
        state: 'custom-order-stats',
        name: 'Custom Order Stats',
        type: 'link',
        icon: 'ti-stats-up'
      },
      {
        state: 'payments',
        name: 'Payment History',
        type: 'link',
        icon: 'ti-credit-card'
      }
    ]
  },
  {
    label: 'Other',
    main: [
      {
        state: 'profile',
        name: 'Profile',
        type: 'link',
        icon: 'ti-user'
      },
      {
        state: 'reviews',
        name: 'Reviews',
        type: 'link',
        icon: 'ti-face-smile'
      },
      {
        state: 'user-registration',
        name: 'New Client Registration',
        type: 'link',
        icon: 'ti-plus'
      },
      {
        state: 'invoice',
        name: 'Commission Invoice',
        type: 'link',
        icon: 'ti-credit-card'
      },
      /*{
        state: 'contacts',
        name: 'Support',
        type: 'link',
        icon: 'ti-star'
      },*/

    ]
  },

];

@Injectable()
export class MenuItems {
  getAll(): Menu[] {
    return MENUITEMS;
  }

  /*add(menu: Menu) {
    MENUITEMS.push(menu);
  }*/
}
